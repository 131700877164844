<template>
  <div class="content">
    <div class="flex-center-sb">
      <div>
        <div class="title">$ARB On-Chain Trustless OTC</div>
        <div class="sub-title">TRUST CODE & CONTRACT, NOT UR CUSTODY / CODE IS LAW / AUDITED BY CHATGPT, USE AT YOUR OWN RISK</div>
      </div>
      <n-button type="primary" @click="showReferer">Refer</n-button>
    </div>
    <div v-if="startTime" class="countdown">
      <div v-if="duration(startTime)" class="flex-center">Unlock Countdown: 
        <n-countdown :duration="duration(startTime)" :active="true" :precision="0" />
      </div>
      <div v-else class="card-title flex-center">ARB Token Airdrop Countdown Ended. Please finalize your transaction.</div>
    </div>
    <div class="links flex-center">
      <a href="https://bridge.arbitrum.io/" target="_blank" rel="noopener noreferrer">
        <svg t="1679318205218" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4623" width="18" height="18"><path d="M574 665.4c-3.1-3.1-8.2-3.1-11.3 0L446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3l-39.8-39.8c-3.1-3.1-8.2-3.1-11.3 0L191.4 526.5c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3L574 665.4zM832.6 191.4c-84.6-84.6-221.5-84.6-306 0L410.3 307.6c-3.1 3.1-3.1 8.2 0 11.3l39.7 39.7c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204L665.3 562.6c-3.1 3.1-3.1 8.2 0 11.3l39.8 39.8c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c84.5-84.6 84.5-221.5 0-306.1z" p-id="4624" fill="#28a0f0"></path><path d="M610.1 372.3c-3.1-3.1-8.2-3.1-11.3 0L372.3 598.7c-3.1 3.1-3.1 8.2 0 11.3l39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l226.4-226.4c3.1-3.1 3.1-8.2 0-11.3l-39.5-39.6z" p-id="4625" fill="#28a0f0"></path></svg>
        Arbitrum Bridge
      </a>
      <a href="https://arbiscan.io/token/0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9" target="_blank" rel="noopener noreferrer">
        <svg t="1679322376684" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5504" width="18" height="18"><path d="M508.72320034 967.11111074c-107.3152 0-204.48142187-46.5123552-278.43697813-111.0471104 282.624-33.7692448 497.0723552-121.74222187 643.2995552-263.87342293A1708.6236448 1708.6236448 0 0 1 963.92533367 512.00000034c32.90453333 275.8883552-197.33617813 455.11111147-455.2476448 455.1111104z" fill="#979695" p-id="5505"></path><path d="M240.93582221 465.57866701c0 40.3683552 2.13902187 187.96088853 0 274.432-14.5635552 14.79111147-69.4499552 27.98933333-122.0608 14.7911104C92.47857741 711.33866701 56.88888887 610.75911074 56.88888887 520.19200034 56.88888887 265.55733367 246.39715554 56.88888887 500.34915554 56.88888887c222.68586667 0 423.43537813 167.84497813 423.43537813 344.15502187 0 31.58471147-133.9392 139.4460448-167.4808896 163.52142293 0-48.69688853-0.68266667-208.44088853 0-263.87342293 0.4096-40.59591147-116.69048853-47.46808853-140.81137706-20.84408853v347.43182186c-2.09351147 27.17013333-30.9475552 31.49368853-51.6096 29.4912V371.91680034c-34.86151147-32.1763552-94.07146667-33.72373333-142.99591147 0v324.4032c-16.1564448 19.4787552-19.0236448 34.13333333-42.46186667 43.69066667-3.41333333-72.7267552 4.96071147-216.63288853 0-274.432-12.97066667-32.9955552-102.49102187-32.9955552-137.48906666 0z" fill="#0C598B" p-id="5506"></path></svg>
        USDT Contact
      </a>
      <a href="https://arbiscan.io/token/0x912CE59144191C1204E64559FE8253a0e49E6548" target="_blank" rel="noopener noreferrer">
        <svg t="1679322376684" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5504" width="18" height="18"><path d="M508.72320034 967.11111074c-107.3152 0-204.48142187-46.5123552-278.43697813-111.0471104 282.624-33.7692448 497.0723552-121.74222187 643.2995552-263.87342293A1708.6236448 1708.6236448 0 0 1 963.92533367 512.00000034c32.90453333 275.8883552-197.33617813 455.11111147-455.2476448 455.1111104z" fill="#979695" p-id="5505"></path><path d="M240.93582221 465.57866701c0 40.3683552 2.13902187 187.96088853 0 274.432-14.5635552 14.79111147-69.4499552 27.98933333-122.0608 14.7911104C92.47857741 711.33866701 56.88888887 610.75911074 56.88888887 520.19200034 56.88888887 265.55733367 246.39715554 56.88888887 500.34915554 56.88888887c222.68586667 0 423.43537813 167.84497813 423.43537813 344.15502187 0 31.58471147-133.9392 139.4460448-167.4808896 163.52142293 0-48.69688853-0.68266667-208.44088853 0-263.87342293 0.4096-40.59591147-116.69048853-47.46808853-140.81137706-20.84408853v347.43182186c-2.09351147 27.17013333-30.9475552 31.49368853-51.6096 29.4912V371.91680034c-34.86151147-32.1763552-94.07146667-33.72373333-142.99591147 0v324.4032c-16.1564448 19.4787552-19.0236448 34.13333333-42.46186667 43.69066667-3.41333333-72.7267552 4.96071147-216.63288853 0-274.432-12.97066667-32.9955552-102.49102187-32.9955552-137.48906666 0z" fill="#0C598B" p-id="5506"></path></svg>
        ARB Contact
      </a>
      <a href="https://arbiscan.io/address/0xcCcF31EE81a8ca9f4C9Ff882AAa8DC7aA074293e" target="_blank" rel="noopener noreferrer">
        <svg t="1679322376684" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5504" width="18" height="18"><path d="M508.72320034 967.11111074c-107.3152 0-204.48142187-46.5123552-278.43697813-111.0471104 282.624-33.7692448 497.0723552-121.74222187 643.2995552-263.87342293A1708.6236448 1708.6236448 0 0 1 963.92533367 512.00000034c32.90453333 275.8883552-197.33617813 455.11111147-455.2476448 455.1111104z" fill="#979695" p-id="5505"></path><path d="M240.93582221 465.57866701c0 40.3683552 2.13902187 187.96088853 0 274.432-14.5635552 14.79111147-69.4499552 27.98933333-122.0608 14.7911104C92.47857741 711.33866701 56.88888887 610.75911074 56.88888887 520.19200034 56.88888887 265.55733367 246.39715554 56.88888887 500.34915554 56.88888887c222.68586667 0 423.43537813 167.84497813 423.43537813 344.15502187 0 31.58471147-133.9392 139.4460448-167.4808896 163.52142293 0-48.69688853-0.68266667-208.44088853 0-263.87342293 0.4096-40.59591147-116.69048853-47.46808853-140.81137706-20.84408853v347.43182186c-2.09351147 27.17013333-30.9475552 31.49368853-51.6096 29.4912V371.91680034c-34.86151147-32.1763552-94.07146667-33.72373333-142.99591147 0v324.4032c-16.1564448 19.4787552-19.0236448 34.13333333-42.46186667 43.69066667-3.41333333-72.7267552 4.96071147-216.63288853 0-274.432-12.97066667-32.9955552-102.49102187-32.9955552-137.48906666 0z" fill="#0C598B" p-id="5506"></path></svg>
        OTC Contract
      </a>
    </div>
    <div class="card">
      <div>
        <div class="card-title">Order Info</div>
        <div class="radio-group form-item">
          <div :class="['radio', formData.direction == true ? 'radio-buy' : '']" @click="formData.direction = true">Buy</div>
          <div :class="['radio', formData.direction == false ? 'radio-sell' : '']" @click="formData.direction = false">Sell</div>
        </div>
        <n-form-item label="Price:" label-width="120" label-align="left" label-placement="left" class="form-item">
          <n-input-number style="width: 500px" v-model:value="formData.price" placeholder="Price input Price" :show-button="false" />
        </n-form-item>
        <n-form-item label="ARB Amount:" label-width="120" label-align="left" label-placement="left" class="form-item">
          <n-input-number style="width: 500px" v-model:value="formData.quantity" placeholder="Price input ARB Amount" :show-button="false" />
        </n-form-item>
        <n-button type="primary" :disabled="!formData.price || !formData.quantity" class="form-item" @click="showModal">Submit & Deposit</n-button>
      </div>
      <div class="table">
        <div class="card-title">Available Offerings</div>
        <div v-if="routeParams && !showAll">
          <n-table :single-line="false">
            <thead>
              <tr>
                <th>Offer From</th>
                <th>Price</th>
                <th>Amount</th>
                <th>Option</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in getList(allOrder)" :key="item.orderId">
                <td>{{formatAddress(item.seller == '0x0000000000000000000000000000000000000000' ? item.buyer : item.seller)}}</td>
                <td>{{formatNumber(item.price, 6)}}</td>
                <td>{{formatNumber(item.quantity, 0)}}</td>
                <td>
                  <n-button size="small" @click="acceptOrder(item.orderId)">Confirm & Stake</n-button>
                </td>
              </tr>
            </tbody>
          </n-table>
        </div>
        <div v-else class="flex-start" style="width: 100%">
          <div v-for="dir in directions" :key="dir" class="table-w">
            <div class="table-title" :style="{color: dir == 'buy' ? '#18a058' : '#d03050'}">{{dir}}</div>
            <n-table v-if="getList(allOrder, dir).length" :single-line="false">
              <thead>
                <tr>
                  <th>Offer From</th>
                  <th>Price</th>
                  <th>Amount</th>
                  <th>Option</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in getList(allOrder, dir)" :key="item.orderId">
                  <td>{{formatAddress(dir == 'buy' ? item.buyer : item.seller)}}</td>
                  <td :style="{color: dir == 'buy' ? '#18a058' : '#d03050'}">{{formatNumber(item.price, 6)}}</td>
                  <td>{{formatNumber(item.quantity, 0)}}</td>
                  <td>
                    <n-button size="small"  @click="acceptOrder(item.orderId)">Confirm</n-button>
                  </td>
                </tr>
              </tbody>
            </n-table>
            <n-empty v-else description="There is no available offerings for now."></n-empty>
          </div>
        </div>
        <p v-if="routeParams && !showAll" class="show-all flex-center-center" @click="showAll = true">
          Check All Available Offerings
          <svg t="1679254850804" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4356" width="14" height="14"><path d="M807.594667 478.378667L206.506667 8.96A10.581333 10.581333 0 0 0 189.44 17.322667v103.082666c0 6.485333 3.072 12.8 8.106667 16.810667L677.546667 512 197.461333 886.784a21.162667 21.162667 0 0 0-8.106666 16.810667v103.082666c0 8.96 10.24 13.824 17.152 8.362667l601.088-469.418667a42.666667 42.666667 0 0 0 0-67.242666z" fill="#9ba0a8" fill-opacity=".65" p-id="4357"></path></svg>
        </p>
      </div>
    </div>
    <CreatedModal ref="modal" @created="created" />
    <ShareModal ref="shareModal" />
  </div>
</template>
<script>
import { useStore } from 'vuex'
import { computed, ref, watch, toRaw, onBeforeMount } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useGlobal } from '../hooks/useGlobal'
import CreatedModal from '@/components/CreatedModal.vue'
import ShareModal from '@/components/ShareModal.vue'
import { ethers } from 'ethers'
import { useMessage } from 'naive-ui'
import { ttdErc20, usdtErc20 } from '../libs/config'
export default {
  components: {
    CreatedModal,
    ShareModal
  },
  setup() {
    let ttdContract = null
    let usdtContract = null
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const message = useMessage()
    const { getOrdersByAddress, getAllUnAcceptOrder } = useGlobal()
    const formData = ref({direction: true})
    const showLoading = ref(false)
    const referer = ref('')
    const modal = ref(null)
    const shareModal = ref(null)
    const routeParams = ref('')
    const orderList = ref([])
    const showAll = ref(false)
    const startTime = computed(() => {
      return store.state.startTime
    })
    const address = computed(() => {
      return store.state.address
    })
    const otcContract = computed(() => {
      return store.state.otcContract
    })
    const provider = computed(() => {
      return store.state.provider
    })
    const allOrder = computed(() => {
      return store.state.allOrder
    })
    
    const duration = computed(() => {
      return (time) => {
        let now = new Date().getTime()
        if (time * 1000 > now) {
          return time * 1000 - now
        } else {
          return 0
        }
      }
    })
    const formatAddress = computed(() => {
      return (address) => {
        return address ? `${address.slice(0, 6)}...${address.slice(-4)}` : ''
      }
    })
    const formatNumber = computed(() => {
      return (value, decimals) => {
        return ethers.utils.formatUnits(value.toString(), decimals)
      }
    })
    const getList = (list, type) => {
      let orders = list
      console.log(type)
      if (type == 'sell') {
        orders = list.filter(item => item.buyer == '0x0000000000000000000000000000000000000000')
      } else if (type == 'buy') {
        orders = list.filter(item => item.seller == '0x0000000000000000000000000000000000000000')
      }
      if (routeParams.value && !showAll.value) {
        orders = orders.filter(item => ethers.utils.formatUnits(item.orderId.toString(), 0) == routeParams.value)
      }
      // sort
      if (type == 'sell') {
        orders = orders.sort((a, b) => {
          return a.price - b.price
        })
      } else {
        orders = orders.sort((a, b) => {
          return b.price - a.price
        })
      }
      
      return orders
        
    }
    const showModal = () => {
      let data = JSON.parse(JSON.stringify(formData.value))
      data.title = 'Confirm'
      data.type = 'createOrder'
      data.refererAddress = referer.value ? referer.value : '',
      modal.value.showModal(data)
    }
    const created = () => {
      console.log('created')
      formData.value.price = ''
      formData.value.quantity = ''
      getOrdersByAddress()
      getAllUnAcceptOrder()
    }
    const acceptOrder = (id, type) => {
      console.log(id)
      let order = allOrder.value.find(item => item.orderId == id)
      if (type && type === 'share') {
        order = orderList.value.find(item => item.orderId == id)
      }
      let data = {
        title: 'Confirm',
        type: 'acceptOrder',
        orderId: order.orderId,
        price: ethers.utils.formatUnits(order.price.toString(), 6),
        quantity: ethers.utils.formatUnits(order.quantity.toString(), 0),
        direction: order.buyer == '0x0000000000000000000000000000000000000000' ? true : false,
        refererAddress: referer.value ? referer.value : '',
      }
      modal.value.showModal(data)
    }
    const mint = (type) => {
      if (type == 'usdt') {
        usdtContract.mint(address.value).then(async res => {
          console.log(res)
          await res.wait()
          message.success('successful')
        }).catch(error => {
          console.log(error)
          message.error('error:' + error?.error?.message || error?.message || error)
        })
      } else {
        ttdContract.mint(address.value).then(async res => {
          console.log(res)
          await res.wait()
          message.success('successful')
        }).catch(error => {
          console.log(error)
          message.error('error:' + error?.error?.message || error?.message || error)
        })
      }
    }
    const showReferer = () => {
      shareModal.value.showModal(address.value, 'refer')
    }
    onBeforeMount(() => {
      console.log(route.query.referer)
      let ref = route.query.referer
      if (ref && ethers.utils.isAddress(ref)) {
        referer.value = ref
      }
    })
    const contract = (contractAddress, abi, provider) => {
      return new ethers.Contract(contractAddress, abi, provider)
    }
    watch(() => provider.value, async () => {
      if (provider.value) {
        const signer = toRaw(provider.value).getSigner()
        ttdContract = contract(ttdErc20.address, ttdErc20.abi, signer)
        usdtContract = contract(usdtErc20.address, usdtErc20.abi, signer)
      }
    }, {immediate: true})
    watch(() => otcContract.value, async () => {
      let orderId = route.params.id
      routeParams.value = orderId
      if (orderId && otcContract.value) {
        try {
          let tx = await toRaw(otcContract.value).orders(orderId)
          if (tx.state != 0) {
            message.error('The offer has been expired.')
            showAll.value = true
            return
          }
        } catch (error) {
          console.log(error)
        }
        router.replace('/')
      }
    }, {immediate: true})
    return {
      directions: ['buy', 'sell'],
      shareModal,
      referer,
      startTime,
      routeParams,
      showAll,
      orderList,
      formData,
      showLoading,
      provider,
      modal,
      allOrder,
      formatAddress,
      formatNumber,
      duration,
      showModal,
      created,
      getList,
      acceptOrder,
      mint,
      showReferer
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  padding: 24px;
  box-sizing: border-box;
  border-right: 1px solid rgba(133,141,153,.15);
  .title {
    font-size: 16px;
    font-family: Montserrat-Medium;
  }
  .sub-title {
    font-size: 12px;
    color: #767c82;
    margin-top: 8px;
  }
  .countdown {
    font-size: 14px;
    margin-top: 24px;
    font-family: Montserrat-Medium;
  }
  .links {
    margin-top: 24px;
    a {
      font-size: 12px;
      margin-right: 16px;
      display: flex;
      align-items: center;
      color: #28a0f0;
      svg {
        width: 14px;
        height: 14px;
        margin-right: 4px;
      }
      &:hover {
        color: #108ee2;
        svg {
          path {
            fill: #108ee2;
          }
        }
      }
    }
  }
  .card {
    margin-top: 24px;
    border: 1px solid rgba(133,141,153,.15);
    border-radius: 8px;
    padding: 16px;
    .card-title {
      font-size: 16px;
      margin-top: 8px;
      margin-bottom: 24px;
      font-family: Montserrat-Medium;
    }
    .radio-group {
      display: flex;
      .radio {
        width: 72px;
        height: 32px;
        border: 1px solid rgba(133,141,153,.15);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 13px;
        &:first-child {
          margin-right: 16px;
        }
        &.radio-buy {
          background: #18a058;
          border: 1px solid #18a058;
          color: #fff;
        }
        &.radio-sell {
          background: #d03050;
          border: 1px solid #d03050;
          color: #fff;
        }
      }
    }
    .form-item {
      margin-top: 16px;
    }
    .hint {
      font-size: 13px;
    }
  }
  .table-w {
    flex: 1;
    &:first-child {
      margin-right: 16px;
    }
  }
  .table-title {
    font-size: 16px;
    font-family: Montserrat-Medium;
    margin-bottom: 16px;
    text-transform: uppercase;
  }
  .table {
    margin-top: 32px;
  }
  .show-all {
    margin-top: 16px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    color: #9ba0a8;
    svg {
      margin-left: 4px;
      width: 14px;
      height: 14px;
    }
  }
}
</style>
<style lang="scss">
.n-form-item-feedback-wrapper {
  display: none;
}
.table .n-table {
  --n-td-padding: 6px 8px !important;
}
</style>