// import { useMessage } from 'naive-ui'
import store from '../store/index'
import { toRaw } from "vue";

export const useGlobal = () => {
  const getBuilderAddress = async () => {
    const otcContract = store.state.otcContract
    try {
      let tx = await toRaw(otcContract).builderAddress()
      let builderAddress = tx
      store.commit('setBuilderAddress', builderAddress)
    } catch (error) {
      console.log(error)
    }
  }
  const getOrdersByAddress = async () => {
    const otcContract = store.state.otcContract
    try {
      let tx = await toRaw(otcContract).getOrdersByAddress()
      let orderList = tx
      store.commit('setOrderList', orderList)
    } catch (error) {
      console.log(error)
    }
  }

  const getAllUnAcceptOrder = async () => {
    const otcContract = store.state.otcContract
    try {
      let tx = await toRaw(otcContract).getAllUnAcceptOrder()
      let allOrder = tx
      console.log(allOrder[0])
      store.commit('setAllOrder', allOrder)
    } catch (error) {
      console.log(error)
    }
  }

  const getEndTime = async () => {
    const otcContract = store.state.otcContract
    try {
      let tx = await toRaw(otcContract).endTime()
      let endTime = tx
      store.commit('setEndTime', endTime)
    } catch (error) {
      console.log(error)
    }
  }

  const getStartTime = async () => {
    const otcContract = store.state.otcContract
    try {
      let tx = await toRaw(otcContract).startTime()
      let startTime = tx
      store.commit('setStartTime', startTime)
    } catch (error) {
      console.log(error)
    }
  }

  return {
    getAllUnAcceptOrder,
    getOrdersByAddress,
    getStartTime,
    getEndTime,
    getBuilderAddress
  }
}
