<template>
  <n-modal
    v-model:show="show" 
    :mask-closable="false" 
    :style="{width: '600px', 'border-radius': '10px'}"
    preset="card"
    :title="modalTitle"
    @afterLeave="cancel"
  >
    <n-spin :show="loading">
      <div class="content">
        <p>You are <span :style="{color: direction ? '#18a058' : '#d03050'}">{{direction ? 'buying' : 'selling'}}</span> {{quantity}}ARB at {{price}}USDT/ARB, the total price is {{total}}</p>
        <p class="flex-center">ARB Address: <a href="https://arbiscan.io/token/0x912CE59144191C1204E64559FE8253a0e49E6548" target="_blank" rel="noopener noreferrer">0x912ce59144191c1204e64559fe8253a0e49e6548</a></p>
        <div v-if="!direction" class="hint">
          <p>* You need to deposit {{quantity}} ARB in the contract within 4 hours after block {{getDate(1679575343000)}}, or your staked USDT will be paid to the buyer as a penalty.</p>
          <p>* endTime {{getDate(1679575343000 + 14400000)}}</p>
          <p>* Please confirm you are eligible to the ARB airdrop.</p>
        </div>
        
        <div class="btns flex-center">
          <n-button v-if="isApprove" type="primary" class="btn-item" @click="approve">Approve</n-button>
          <n-button v-else type="primary" class="btn-item" @click="deposit">Deposit {{total}}USDT</n-button>
        </div>
      </div>
    </n-spin>
  </n-modal>
</template>
<script>
import { defineComponent, ref, watch, toRaw, computed } from "vue";
import { useStore } from 'vuex'
import {ethers} from 'ethers'
import { useMessage } from 'naive-ui'
import { useIsActivating } from '../hooks/useIsActivating'
import { usdtErc20, arbOtc } from '../libs/config'
export default defineComponent({
  setup(props, {emit}) {
    let usdtContract = null

    const { getProvider } = useIsActivating()
    const store = useStore()
    const message = useMessage()
    const show = ref(false)
    const loading = ref(false)
    const isApprove = ref(true)
    const modalTitle = ref('Modal Title')
    const refererAddress = ref('')
    const direction = ref(true)
    const price = ref(0)
    const orderId = ref('')
    const quantity = ref(0)
    const total = ref(0)
    const provider = computed(() => {
      return store.state.provider
    })
    const address = computed(() => {
      return store.state.address
    })
    const otcContract = computed(() => {
      return store.state.otcContract
    })
    const builderAddress = computed(() => {
      return store.state.builderAddress
    })
    const getDate = (time) => {
      return new Date(time).toLocaleString()
    }
    const cancel = () => {
      show.value = false
      isApprove.value = true
      modalTitle.value = ''
      direction.value = true
      price.value = 0
      quantity.value = 0
      total.value = 0
    }
    const getApprove = async () => {
      loading.value = true
      let usdtAllowance = 0
      try {
        usdtAllowance = await usdtContract.allowance(address.value, arbOtc.address)
        usdtAllowance = ethers.utils.formatUnits(usdtAllowance.toString(), 6)
      } catch (error) {
        console.log(error)      
      }
      loading.value = false
      if ((usdtAllowance * 1) >= (total.value * 1)) {
        isApprove.value = false
      } else {
        isApprove.value = true
      }
    }
    const showModal = async (data) => {
      show.value = true
      direction.value = data.direction
      price.value = data.price
      quantity.value = data.quantity
      modalTitle.value = data.title
      orderId.value = data.orderId
      refererAddress.value = data.refererAddress || builderAddress.value
      total.value = (price.value * quantity.value)
      getApprove()
    }
    const contract = (contractAddress, abi, provider) => {
      return new ethers.Contract(contractAddress, abi, provider)
    }
    const approve = async () => {
      if (provider.value) {
        loading.value = true
        try {
          let tx = await usdtContract.approve(arbOtc.address, ethers.utils.parseUnits((total.value * 2000000).toString(), 6))
          await tx.wait()
          loading.value = false
          getApprove()
        } catch (error) {
          console.error(error)
          loading.value = false
        }
      } else {
        getProvider()
      }
    }
    const deposit = async () => {
      if (provider.value) {
        loading.value = true
        try {
          if (orderId.value) {
            let tx = await toRaw(otcContract.value).acceptOrder(orderId.value)
            await tx.wait()
            message.success('Offer Accepted Successfully.')
          } else {
            let tx = await toRaw(otcContract.value).createOrder(direction.value, ethers.utils.parseUnits(toRaw(price.value).toString(), 6), quantity.value, refererAddress.value)
            console.log(tx)
            await tx.wait()
            message.success('Offer Accepted Successfully.')
          }
          
          loading.value = false
          emit('created')
          cancel()
        } catch (error) {
          loading.value = false
          message.error('error: ' + error?.error?.message || error?.message || error)
        }
      } else {
        getProvider()
      }
    }
    watch(() => provider.value, async () => {
      if (provider.value) {
        const signer = toRaw(provider.value).getSigner()
        usdtContract = contract(usdtErc20.address, usdtErc20.abi, signer)
      }
    }, {immediate: true})
    return {
      refererAddress,
      isApprove,
      loading,
      total,
      quantity,
      price,
      direction,
      modalTitle,
      show,
      getDate,
      approve,
      cancel,
      showModal,
      deposit
    }
  }
})
</script>
<style lang="scss" scoped>
.content {
  p {
    margin-bottom: 16px;
    span {
      font-family: Montserrat-Medium;
    }
    a {
      margin-left: .5em;
      color: #28a0f0;
      &:hover {
        color: #108ee2;
      }
    }
  }
  .hint {
      font-size: 12px;
      color: #d03050;
      font-family: Montserrat-Medium;
      margin-top: 16px;
      p {
        margin-top: 8px;
      }
    }
  .btns {
    margin-top: 24px;
    .btn-item {
      margin-right: 16px;
    }
  }
}
</style>