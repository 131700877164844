<template>
  <n-modal
    v-model:show="show" 
    :mask-closable="false" 
    :style="{width: '600px', 'border-radius': '10px'}"
    preset="card"
    :title="modalTitle"
    @afterLeave="cancel"
  >
    <n-spin :show="loading">
      <div v-if="address == order.seller" class="content">
        <p>You can withdraw {{(order.total * 2 * 99) / 100}} USDT after deposit {{order.quantity}} ARB </p>
        <div class="btns flex-center">
          <n-button v-if="isApprove" type="primary" class="btn-item" @click="approve">Approve</n-button>
          <n-button v-else type="primary" class="btn-item" @click="deposit">deposit {{total}}ARB and claim USDT</n-button>
        </div>
      </div>
    </n-spin>
  </n-modal>
</template>
<script>
import { defineComponent, ref, watch, toRaw, computed } from "vue";
import { useStore } from 'vuex'
import { ethers } from 'ethers'
import { useMessage } from 'naive-ui'
import { useIsActivating } from '../hooks/useIsActivating'
import { useGlobal } from '../hooks/useGlobal'
import { ttdErc20, arbOtc } from '../libs/config'

export default defineComponent({
  setup() {
    let ttdContract = null
    const { getOrdersByAddress } = useGlobal()
    const { getProvider } = useIsActivating()
    const store = useStore()
    const message = useMessage()
    const show = ref(false)
    const loading = ref(false)
    const isApprove = ref(true)
    const order = ref({})
    const modalTitle = ref('')
    const provider = computed(() => {
      return store.state.provider
    })
    const address = computed(() => {
      return store.state.address
    })
    const otcContract = computed(() => {
      return store.state.otcContract
    })
    const cancel = () => {
      show.value = false
      isApprove.value = true
    }
    const getApprove = async () => {
      loading.value = true
      let ttdAllowance = 0
      try {
        ttdAllowance = await ttdContract.allowance(address.value, arbOtc.address)
        ttdAllowance = ethers.utils.formatUnits(ttdAllowance.toString(), 18)
      } catch (error) {
        console.log(error)
      }
      if ((ttdAllowance * 1) >= (order.value.total * 1)) {
        isApprove.value = false
      } else {
        isApprove.value = true
      }
      loading.value = false
    }
    const showModal = async (o) => {
      o = JSON.parse(JSON.stringify(o))
      show.value = true
      order.value = o
      modalTitle.value = o.title
      order.value.total = order.value.price * order.value.quantity
      getApprove()
    }
    const contract = (contractAddress, abi, provider) => {
      return new ethers.Contract(contractAddress, abi, provider)
    }
    const approve = async () => {
      if (provider.value) {
        loading.value = true
        try {
          let tx = await ttdContract.approve(arbOtc.address, ethers.utils.parseUnits((order.value.total * 200000).toString(), 18))
          await tx.wait()
          loading.value = false
          getApprove()
        } catch (error) {
          console.error(error)
          loading.value = false
        }
      } else {
        getProvider()
      }
    }
    const deposit = async () => {
      if (provider.value) {
        loading.value = true
        try {
          if (order.value.seller == address.value) {
            let tx = await toRaw(otcContract.value).completeOrder(order.value.orderId)
            console.log(tx)
            await tx.wait() 
          } else {
            console.log(1)
          }
          loading.value = false
          getOrdersByAddress()
          cancel()
          message.success('successful')
        } catch (error) {
          loading.value = false
          message.error('error:' + error?.error?.message || error?.message || error)
        }
      } else {
        getProvider()
      }
    }
    watch(() => provider.value, async () => {
      if (provider.value) {
        const signer = toRaw(provider.value).getSigner()
        ttdContract = contract(ttdErc20.address, ttdErc20.abi, signer)
        console.log(ttdContract)
      }
    }, {immediate: true})
    return {
      order,
      address,
      isApprove,
      loading,
      show,
      modalTitle,
      approve,
      cancel,
      showModal,
      deposit
    }
  }
})
</script>
<style lang="scss" scoped>
.content {
  p {
    margin-bottom: 16px;
    span {
      font-family: Montserrat-Medium;
    }
  }
  .hint {
      font-size: 12px;
      color: #d03050;
      font-family: Montserrat-Medium;
      margin-top: 16px;
      p {
        margin-top: 8px;
      }
    }
  .btns {
    margin-top: 24px;
    .btn-item {
      margin-right: 16px;
    }
  }
}
</style>
<style>
  .n-modal-container {
    z-index: 3000 !important;
  }
</style>