<template>
  <n-modal
    v-model:show="show"
    :mask-closable="false"
    class="custom-card"
    preset="card"
    :style="{width: '600px', 'border-radius': '10px'}"
    :title="type ? 'Refer' : 'Share'"
    :on-after-leave="afterLeave"
  >
    <div>
      <div class="desc">Share your link</div>
      <div class="input flex-center" @click="copy(link)">
        <div class="link flex-center" ><p>{{link}}</p></div>
        <div class="copy-btn flex-center-center">Copy link</div>
      </div>
    </div>
  </n-modal>
</template>
<script>
import { ref } from 'vue'
import { useUtils } from '../hooks/useUtils'
export default {
  setup() {
    const { copy } = useUtils()
    const show = ref(false)
    const link = ref('')
    const type = ref('')
    const afterLeave = () => {
      show.value = false
    }
    const showModal = (id, tp) => {
      type.value = tp || ''
      if (tp && tp == 'refer') {
        link.value = `${window.location.origin}/?referer=${id}`
        show.value = true
      } else {
        let origin = window.location.origin
        link.value = `${origin}/${id}`
        show.value = true
      }
    }
    return {
      type,
      link,
      show,
      copy,
      afterLeave,
      showModal,
    }
  }
}
</script>
<style lang="scss" scoped>
.input {
  margin-top: 16px;
  padding: 5px 5px 5px 16px;
  height: 50px;
  background: #fff;
  box-sizing: border-box;
  border: 1px solid rgba(47, 52, 61, 0.4);
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  .link {
    flex: 1;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: rgb(31, 34, 37);
    background: none;
    border: none;
    outline: none;
    p {
      width: 420px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .copy-btn {
    flex: 0 0 98px;
    width: 98px;
    height: 40px;
    background: #18a058;
    border-radius: 4px;
    margin-left: 10px;
    color: #fff;
  }
}
</style>