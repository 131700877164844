import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/reset.scss'
import './assets/css/main.scss'

import { create, NSelect, NConfigProvider, NMessageProvider, NModal, NCard, NDrawer, NTable, NEmpty,
  NFormItem, NInput, NInputNumber, NPopconfirm, NTabs, NTabPane, NCollapse, NDivider, NResult,
  NCollapseItem, NPopover, NSpin, NDialogProvider, NInputGroup, NButton, NImage, NTooltip,
  NForm, NLayout, NCountdown } from 'naive-ui'
const naive = create({
  components: [ NSelect, NConfigProvider, NMessageProvider, NLayout, NCard, NResult, NInputNumber,
    NModal, NFormItem, NInput, NPopconfirm, NTabs, NTabPane, NButton, NDivider, NDrawer, NTable, NEmpty,
    NCollapse, NCollapseItem, NPopover, NSpin, NDialogProvider, NInputGroup, NImage, NTooltip, NForm, NCountdown ]
})
createApp(App).use(store).use(naive).use(router).mount('#app')
