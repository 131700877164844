<template>
  <div class="drawer">
    <div v-show="endTime" class="content">
      <div class="title">Order</div>
      <div class="card" v-for="(item, index) in orderList" :key="index">
        <div class="hd flex-center-sb">
          <div class="flex-center">
            <p class="hd-type" :style="{background: address == item.seller ? '#d03050' : '#18a058'}">{{address == item.seller ? 'ASK' : 'BID'}}</p>
            <p style="color: #9BA0A8">{{orderState(item.state)}}</p>
            <p class="flex-center" style="color: #9BA0A8" v-if="item.state != 0 && item.state != 4">{{formatAddress(item.seller)}} 
              <svg style="margin: 0 8px" t="1679255832586" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5723" width="14" height="14"><path d="M139.636364 520.067879a31.030303 31.030303 0 0 1 31.030303-31.030303h682.666666a31.030303 31.030303 0 1 1 0 62.060606H170.666667a31.030303 31.030303 0 0 1-31.030303-31.030303z" fill="#9BA0A8" p-id="5724"></path><path d="M552.122182 218.856727a31.030303 31.030303 0 0 1 43.876848 0l279.272728 279.272728a31.030303 31.030303 0 0 1 0 43.876848l-279.272728 279.272727a31.030303 31.030303 0 0 1-43.876848-43.876848l257.334303-257.334303-257.334303-257.334303a31.030303 31.030303 0 0 1 0-43.876849z" fill="#9BA0A8" p-id="5725"></path></svg>
              {{formatAddress(item.buyer)}}</p>
          </div>
          <svg v-if="item.state == 0" @click="showShare(item.orderId)" t="1679248976090" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3081" width="18" height="18"><path d="M898.398191 601.423691c-25.952617 0-47.391736 21.439118-47.391736 47.391736l0 242.600551c0 20.874931-16.92562 37.800551-37.800551 37.800551L132.795987 929.216529c-20.874931 0-37.800551-16.92562-37.800551-37.800551L94.995436 209.313499c0-20.874931 16.92562-37.800551 37.800551-37.800551l212.698623 0c25.952617 0 47.391736-21.439118 47.391736-47.391736 0-25.952617-20.874931-47.391736-47.391736-47.391736L132.795987 76.729477C59.451635 76.729477 0.211965 135.969146 0.211965 209.313499l0 682.102479c0 73.344353 59.239669 132.584022 132.584022 132.584022l680.409917 0c72.780165 0 132.584022-59.239669 132.584022-132.584022l0-242.600551C945.789927 622.298623 924.350808 601.423691 898.398191 601.423691zM1020.826841 179.41157c-5.077686-13.540496-15.233058-24.824242-28.209366-31.59449L694.726566 5.641873c-27.080992-12.976309-60.368044-1.692562-73.344353 25.952617-12.976309 27.645179-1.692562 60.368044 25.952617 73.344353l156.27989 75.036915C562.142544 229.060055 464.538136 410.728375 422.224086 490.842975c-48.52011 90.83416-61.496419 231.316804-62.060606 236.958678-2.820937 30.466116 19.746556 56.98292 49.648485 59.803857 1.692562 0 3.385124 0 5.077686 0 28.209366 0 51.905234-21.439118 54.726171-50.212672 2.820937-33.85124 18.053994-135.969146 49.648485-195.208815 43.442424-81.242975 124.121212-232.445179 340.204959-260.654545 3.949311-0.564187 6.770248-2.256749 10.155372-3.385124l-92.526722 194.080441c-12.976309 27.645179-1.692562 60.368044 25.952617 73.344353 7.898623 3.385124 15.797245 5.641873 23.695868 5.641873 20.310744 0 40.0573-11.283747 49.648485-31.59449l142.175207-298.455096C1024.776153 208.185124 1025.34034 192.952066 1020.826841 179.41157z" p-id="3082" fill="#4C4F53"></path></svg>
        </div>
        <div class="bd">
          <p class="flex-center" style="margin-bottom: 12px"><span>ARB Amount: {{formatNumber(item.quantity, 0)}}</span> <span>Price: {{formatNumber(item.price, 6)}}</span></p>
          <div class="flex-center-sb">
            <div class="price">Total: {{formatNumber(item.price, 6) * formatNumber(item.quantity, 0)}}USDT</div>
            <n-button v-if="showBtn(item)" @click="claim(item.orderId, showBtn(item))">{{btnTitle(item)}}</n-button>
          </div>
        </div>
      </div>
    </div>
    <OrderModal ref="orderModal" />
    <ShareModal ref="shareModal" />
  </div>
</template>
<script>
import { defineComponent, ref, computed, toRaw } from "vue";
import OrderModal from '@/components/OrderModal.vue'
import ShareModal from '@/components/ShareModal.vue'
import { useStore } from 'vuex'
import {ethers} from 'ethers'
import { useMessage, useDialog } from 'naive-ui'
import { useGlobal } from '../hooks/useGlobal'
export default defineComponent({
  components: {
    OrderModal,
    ShareModal
  },
  setup() {
    const store = useStore()
    const message = useMessage()
    const dialog = useDialog()
    const { getOrdersByAddress, getAllUnAcceptOrder } = useGlobal()
    const show = ref(false)
    const orderModal = ref(null)
    const shareModal = ref(null)
    const startTime = computed(() => {
      return store.state.startTime
    })
    const endTime = computed(() => {
      return store.state.endTime
    })
    const address = computed(() => {
      return store.state.address
    })
    const otcContract = computed(() => {
      return store.state.otcContract
    })
    const orderList = computed(() => {
      return store.state.orderList
    })
    const formatAddress = computed(() => {
      return (address) => {
        return address ? `${address.slice(0, 6)}...${address.slice(-4)}` : ''
      }
    })
    const orderState = computed(() => {
      return (state) => {
        if (state == 0) {
          return 'created'
        } else if (state == 1) {
          return 'accepted'
        } else if (state == 2) {
          return 'completed'
        } else if (state == 3) {
          return 'finished'
        } else if (state == 4) {
          return 'canceled'
        }
      }
    })
    const showBtn = computed(() => {
      return (order) => {
        let now = Math.floor(new Date().getTime() / 1000)
        let state = order.state
        if (order.seller == address.value) {
          if (state == 0) {
            return 'cancel'
          } else if (state == 1) {
            if (now > startTime.value && now < endTime.value)
              return 'deposit'
            else
              return ''
          } else if (state == 2) {
            return ''
          } else if (state == 3) {
            return ''
          } else if (state == 4) {
            return ''
          }
        } else {
          if (state == 0) {
            return 'cancel'
          } else if (state == 1) { 
            if (now > endTime.value)
              return 'claim'
            else
              return false
          } else if (state == 2) {
            return false
          } else if (state == 3) {
            return false
          } else if (state == 4) {
            return false
          }
        }
      }
    })
    const btnTitle = computed(() => {
      return (order) => {
        let now = Math.floor(new Date().getTime() / 1000)
        let state = order.state
        if (order.seller == address.value) {
          if (state == 0) {
            return 'Cancel Offerings'
          } else if (state == 1) {
            if (now > startTime.value && now < endTime.value)
              return 'Deposit ARB (Delivery)'
            else
              return ''
          } else if (state == 2) {
            return ''
          } else if (state == 3) {
            return ''
          } else if (state == 4) {
            return ''
          }
        } else {
          if (state == 0) {
            return 'Cancel Offerings'
          } else if (state == 1) { 
            if (now > endTime.value)
              return 'Mandatory Delivery (2x USDT)'
            else
              return false
          } else if (state == 2) {
            return false
          } else if (state == 3) {
            return false
          } else if (state == 4) {
            return false
          }
        }
      }
    })
    const toFixed = computed(() => {
      return (value) => {
        return (+value).toFixed(2)
      }
    })
    const formatNumber = computed(() => {
      return (value, decimals) => {
        return ethers.utils.formatUnits(value.toString(), decimals)
      }
    })
    const claim = (id, type) => {
      if (type == 'cancel') {
        const d = dialog.create({
          title: 'Confirm',
          showIcon: false,
          closeOnEsc: false,
          maskClosable: false,
          content: 'Are you cancelling this offer?',
          positiveText: 'Yes',
          negativeText: 'No',
          onPositiveClick: async () => {
            d.loading = true
            try {
              let tx = await toRaw(otcContract.value).cancelOrder(id)
              await tx.wait() 
              getOrdersByAddress()
              getAllUnAcceptOrder()
              message.success('The offer has been cancelled.')
              d.loading = false
            } catch (error) {
              message.error('error:' + error?.error?.message || error?.message || error)
              d.loading = false
            }
          }
        })
      } else if (type == 'deposit') {
        let order = orderList.value.find(item => item.orderId == id)
        let { price, quantity, seller, buyer, orderId } = order
        let data = {
          price: ethers.utils.formatUnits(price.toString(), 6),
          quantity: ethers.utils.formatUnits(quantity.toString(), 0),
          seller,
          buyer,
          orderId,
          title: 'Finalize the transaction.'
        }
        orderModal.value.showModal(data)
      } else if (type == 'claim') {
        const d = dialog.create({
          title: 'Finalize the transaction',
          showIcon: false,
          closeOnEsc: false,
          maskClosable: false,
          content: `The seller didn't deposit ARB in the due time, you can withdraw your principal and the seller's staked USDT.`,
          positiveText: 'Claim',
          negativeText: 'Cancel',
          onPositiveClick: async () => {
            d.loading = true
            try {
              let tx = await toRaw(otcContract.value).finishOrder(id)
              await tx.wait() 
              getOrdersByAddress()
              getAllUnAcceptOrder()
              message.success('successful')
              d.loading = false
            } catch (error) {
              message.error('error:' + error?.error?.message || error?.message || error)
              d.loading = false
            }
          }
        });
      }
    }
    const showShare = (id) => {
      shareModal.value.showModal(ethers.utils.formatUnits(id.toString(), 0))
    }
    return {
      shareModal,
      endTime,
      startTime,
      orderModal,
      address,
      orderList,
      show,
      formatAddress,
      formatNumber,
      toFixed,
      claim,
      btnTitle,
      showBtn,
      orderState,
      showShare
    }
  }
})
</script>
<style lang="scss" scoped>
.drawer {
  height: calc(100vh - 60px);
  overflow-y: auto;
  width: 450px;
  position: sticky;
  top: 0px;
  flex: 0 0 450px;
}
.content {
  padding: 24px;
  box-sizing: border-box;
  .title {
    font-size: 18px;
    font-family: Montserrat-bold;
    margin-bottom: 24px;
  }
  .card {
    margin-top: 24px;
    border: 1px solid rgba(133,141,153,.15);
    border-radius: 8px;
    padding: 16px;
    .hd {
      font-size: 14px;
      font-family: Montserrat-Medium;
      svg {
        cursor: pointer;
      }
      .hd-type {
        padding: 6px 10px;
        border-radius: 4px;
        font-size: 12px;
        color: #fff;
      }
      p {
        margin-right: 16px;
      }
    }
    .bd {
      margin-top: 16px;
      justify-content: space-between;
      .price {
        font-size: 18px;
      }
      p {
        font-size: 14px;
        color: #9BA0A8;
        margin-bottom: 0;
        span {
          margin-right: 20px
        }
      }
    }
  }
}
</style>