<template>
  <n-config-provider >
    <n-message-provider>
      <n-dialog-provider>
        <router-view />
      </n-dialog-provider>
    </n-message-provider>
  </n-config-provider>
</template>
<script>
import { defineComponent, onBeforeMount, watch, toRaw, computed } from 'vue'
import { useStore } from 'vuex'
import { useGlobal } from './hooks/useGlobal'
import { useIsActivating } from './hooks/useIsActivating'
import { arbOtc } from './libs/config'
import { ethers } from 'ethers'
export default defineComponent({
  setup() {
    const store = useStore()
    const { getProvider } = useIsActivating()
    const { getOrdersByAddress, getAllUnAcceptOrder, getEndTime, getStartTime, getBuilderAddress } = useGlobal()
    const provider = computed(() => {
      return store.state.provider
    })
    
    const contract = (contractAddress, abi, provider) => {
      return new ethers.Contract(contractAddress, abi, provider)
    }

    const fn = () => {
      let ethereum = window.ethereum
      ethereum.on("accountsChanged", (accounts) => {
        console.log("账号切换", accounts[0])
        if (!accounts[0]) {
          store.commit('init')
        } else {
          getProvider()
        }
      })
      ethereum.on("chainChanged", (chainId) => {
        console.log("用户切换了链", chainId)
        getProvider()
      })
    }

    onBeforeMount(() => {
      fn()
      getProvider()
    })

    watch(() => provider.value, async () => {
      if (provider.value) {
        const signer = toRaw(provider.value).getSigner()
        const otc = contract(arbOtc.address, arbOtc.abi, signer)
        store.commit('setOtcContract', otc)
        await getEndTime()
        await getStartTime()
        await getOrdersByAddress()
        await getAllUnAcceptOrder()
        await getBuilderAddress()
      }
    })
  }
})

</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 0;
}
</style>
