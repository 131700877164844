<template>
  <div class="home">
    <Nav @showDrawer="showDrawer" />
    <div class="flex-start" style="width: 100%">
      <Main style="flex: 1" /> 
      <Drawer ref="drawer" />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import Nav from '@/components/Nav.vue'
import Drawer from '@/components/Drawer.vue'
import Main from '@/components/Main.vue'

export default {
  name: 'Home',
  components: {
    Nav,
    Drawer,
    Main
  },
  setup() {
    const drawer = ref(null)
    const showDrawer = () => {
      drawer.value.show = true
    }
    return {
      drawer,
      showDrawer
    }
  },
}
</script>
<style lang="scss" scoped>
.home {
  width: 100vw;
}
</style>
