<template>
  <div class="nav flex-center-sb">
    <div class="nav-l">
      <img style="height: 24px;width: auto" src="@/assets/images/logo.png" alt="">
    </div>
    <div v-if="address" class="nav-r flex-center">
      <div class="userinfo flex-center" @click="copy(address)">{{formatAddress(address)}} <span>{{fixed(balance)}}</span></div>
      <div class="avatar" @click="showDrawer">
        <img :src="getBlockie(address)" alt="">
      </div>
    </div>
    <div v-else class="nav-r flex-center" @click="login">
      <n-button strong secondary class="btn">Connect Wallet</n-button>
      <div class="avatar">
        <img src="@/assets/images/defaultAvatar.svg" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import { useIsActivating } from '../hooks/useIsActivating'
import { useUtils } from '../hooks/useUtils'
import makeBlockie from 'ethereum-blockies-base64'
export default {
  name: 'Nav',
  setup(props, {emit}) {
    const store = useStore()
    const { copy } = useUtils()
    const { getProvider } = useIsActivating()
    const address = computed(() => {
      return store.state.address
    })
    const balance = computed(() => {
      return store.state.balance
    })
    const formatAddress = computed(() => {
      return (address) => {
        return address ? `${address.slice(0, 6)}...${address.slice(-4)}` : ''
      }
    })
    const fixed = computed(() => {
      return (value) => {
        return value ? (+value).toFixed(2) : ''
      }
    })
    const getBlockie = computed(() => {
      return (address) => {
        return makeBlockie(address)
      }
    })
    const login = () => {
      getProvider()
    }
    const showDrawer = () => {
      emit('showDrawer')
    }
    return {
      address,
      balance,
      formatAddress,
      getBlockie,
      fixed,
      login,
      copy,
      showDrawer,
    }
  }
}
</script>
<style lang="scss" scoped>
.nav {
  height: 60px;
  border-bottom: 1px solid #EEEFF0;
  padding: 24px;
  box-sizing: border-box;
  .nav-r {
    justify-content: flex-end;
    .userinfo {
      padding: 0 12px;
      border: 1px solid rgba(133,141,153,.15);
      border-radius: 10px;
      font-family: Montserrat-Medium;
      font-size: 13px;
      line-height: 18px;
      height: 34px;
      cursor: pointer;
      span {
        padding-left: 12px;
        font-family: Montserrat-Medium;
        font-size: 13px;
      }
    }
    .avatar {
      margin-left: 24px;
      width: 34px;
      height: 34px;
      border-radius: 34px;
      overflow: hidden;
      cursor: pointer;
      img {
        width: 34px;
        height: 34px;
        object-fit: cover;
      }
    }
    .btn {
      font-family: Montserrat-Medium;
      font-size: 13px;
    }
  }
}
</style>