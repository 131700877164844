import { createStore } from 'vuex'
export default createStore({
  state: {
    provider: null,
    address: '',
    network: null,
    balance: 0,
    otcContract: null,
    orderList: [],
    allOrder: [],
    startTime: 0,
    endTime: 0,
    builderAddress: ''
  },
  mutations: {
    setAddress(state, value) {
      state.address = value
    },
    setProvider(state, value) {
      state.provider = value
    },
    setNetwork(state, value) {
      state.network = value
    },
    setBalance(state, value) {
      state.balance = value
    },
    setOtcContract(state, value) {
      state.otcContract = value
    },
    setOrderList(state, value) {
      state.orderList = value
    },
    setAllOrder(state, value) {
      state.allOrder = value
    },
    setStartTime(state, value) {
      state.startTime = value
    },
    setEndTime(state, value) {
      state.endTime = value
    },
    setBuilderAddress(state, value) {
      state.builderAddress = value
    },
    init(state) {
      state.provider = null
      state.network = null
      state.balance = 0
      state.address = ''
    }
  },
  actions: {
  },
  modules: {
  }
})
